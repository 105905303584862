*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

/*Navbar*/
.navbar{
  background-color: #ffffff !important;
  top: 0 !important;
  position: fixed !important;
  z-index: 999;
  width: 100%;
  --bs-navbar-toggler-border-color: transparent;
  --bs-navbar-toggler-border-radius: none;
  --bs-navbar-toggler-focus-width: none;
  --bs-navbar-toggler-transition: none;
}
.navbar-toggler:focus {
  outline: none;
}
.logo{
  width: 100%;
  height: 40px;
}
.logo1{
  width: 100%;
  height: 15%;
}
.nav-item{
  padding: 0px 10px;
}
.nav-link{
  color: #DAA14C !important;
  font-size: 12px !important;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Plus Jakarta Sans';
}
.pdf{
 margin: 10px !important;
  color: #DAA14C !important;
  font-size: 14px !important;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Plus Jakarta Sans';
}
.nav-link:hover{
  color: black!important;
}
.line{
  color: rgb(0, 0, 0) !important;
}
.line:hover{
  color: #DAA14C !important;
}
.enquiry{
  border: none;
  color: #252525;
  font-weight: bold;
  background-color: #DAA14C;
  padding: 5px 20px;
  /*border-radius: 5px;*/
  cursor: pointer;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
}
.enquiry:hover{
  background-color: black;
  color: white;
}
.dropdown{
background-color: #ffffff;
border: none !important;
}
a.dropdown-item{
color: #DAA14C;
font-weight: 600;
text-transform: uppercase;
font-family: 'Plus Jakarta Sans';
font-size: 12px;
padding: 10px 40px;
border-bottom: 1px solid #f0f0f0;
}
a.dropdown-item:hover{
color: #000000;
}
ul.dropdown-menu.show{
border: none;
}
.navbar-nav .dropdown-menu {
background-color:#ffffff;
border-radius: none !important;
}
.dropdown-menu {
  animation: 0.5s slideup;
  padding: 20px 0px;
}

@keyframes slideup {
  from {
    transform: translateY(50%);
  }

  to {
    transform: translateY(0);
  }
}

/*slider*/
.carousel-item{
  position: relative;
}
.banner{
  width: 100%;
  height: 550px !important;
}
.before::before {
  content:"";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
 /* background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255, 0, 0, 0.594));*/
 background-color: #0000008f;
}
.main-section{
  position: absolute;
  top: 40%;
  left: 25%;
}
.main-concept{
  position: absolute;
  top: 40%;
  left: 20%;
  height: 30%;
  padding: 40px;
}
.main-title{
  color: #fff;
  font-size: 65px;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.main-para{
  color: #ffe600;
  font-size: 24px;
  font-family: 'Plus Jakarta Sans';
}

.landing{
  top: 30%;
  position: absolute;
  z-index: 1;
}
.landing-para{
  font-size: 18px;
  color: #ddd;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.landing-banner{
margin: 40px;
}

/*About us*/
.aboutus{
  width: 100%;
  height: auto;
  background-color: #000000;
}
.background{
  width: 100%;
  height: auto;
  background-color: #e5e5e5;
}
.aboutus-title{
  font-family: 'Plus Jakarta Sans';
  color: rgb(0, 0, 0);
  font-weight: 600;
  padding-left: 10px;
  border-left: 6px solid #e00000;
  font-size: 16px;
}
.cookscape{
  color: white;
  background-color: #000000;
  padding: 5px 10px;
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
}
.experience{
  font-size: 16px;
  color: #DAA14C;
  font-weight: bold;
  margin-top: 30px;
  font-family: 'Plus Jakarta Sans';
}
.aboutus-para{
  color: rgb(26, 26, 26);
  margin-top: 14px;
  margin-bottom: 30px;
  text-align: justify;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
}
.ratio::before{
  padding-top: 0 !important;
}
.aboutus-button-1{
  background-color: black;
  font-size: 18px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  padding: 5px 30px;
  border-radius: 25px;
}
.aboutus-button-1:hover{
  color: white;
  background-color: #e00000;
  border: 1px solid #e00000
}
.aboutus-button-2{
  background-color: #e00000;
  font-size: 12px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px 30px;
  border-radius: 50px;
  margin-left: 10px;
}
.aboutus-button-2:hover{
  color: white;
  background-color: black;
  border: 1px solid white;
}

.box{
  background-color: #DAA14C;
  position: absolute;
  height: 200px;
  width: 250px;
  margin-top: -140px;
  margin-left: 200px;
  padding: 20px;
  
}
.box-icon i{
  font-size: 26px;
  color: white;
  padding-left: 80px;
}
.box-title{
  font-size: 16px;
  color: white;
  text-align: center;
}
.box-para{
  font-size: 14px;
  color: white;
  text-align: center;
}

/*Interior Services*/
.headings{
  font-family: 'Plus Jakarta Sans';
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}
.product-image {
  position: relative;
  width: 100%;
}
.image {
  display: block;
  width: 100%;
  height: 250px;

}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e00000b5;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: .3s ease;
  transition: .3s ease;
}
.product-image:hover .overlay {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  font-weight: bold;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}

/*Why choose us*/
.icon{
  font-size: 26px;
  color: #DAA14C;
  text-align: center;
}
.services-row{
  padding: 0px 0px;
}
.services-title{
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  font-family: 'Plus Jakarta Sans';
}
.services-title-2{
  color: rgb(24, 24, 24);
  text-align: center;
  font-size: 20px;
  padding: 20px;
  font-family: 'Plus Jakarta Sans';
}
.services-title-3{
  color: #060606;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}
.services-para{
  color: rgb(26, 26, 26);
  font-size: 14px;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}
.services-bg-image{
  padding: 20px;
}
/*FAQ*/
.callback{
  width: 100%;
  height: auto;
  background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)),url(images/home/interior-design.webp);
  background-position: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
}
.faq-image{
  width: 400px;
  height: 350px;
  margin: 10px;
}
.testimonial-img{
  width: 100%;
  height: auto;
}
.accordion{
  padding-top: 20px;
}
.accordion-button{
  color: #000000 !important;
  border: none !important;
  font-size: 14px !important;
  
}
.accordion-button:not(.collapsed) {
  color: white !important;
  font-size: 14px;
  font-weight: bold;
  background-color: #e00000 !important ;
  font-family: 'Plus Jakarta Sans';
}
.accordion-item{
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
}

/*Progress*/
.client{
  color: rgb(24, 24, 24);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}
.progress{
  width: 120px;
  height: 120px;
  line-height: 120px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.progress:after{
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.progress > span{
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left{
  left: 0;
}
.progress .progress-bar{
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progress .progress-left .progress-bar{
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right{
  right: 0;
}
.progress .progress-right .progress-bar{
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.progress .progress-value{
  width: 85%;
  height: 85%;
  border-radius: 50%;
  font-size: 20px;
  line-height: 100px;
  text-align: center;
  position: absolute;
  top: 5.5%;
  left: 5.5%;
}
.progress.blue .progress-bar{
  border-color: #DAA14C;
}
.progress.blue .progress-value{
  color: #DAA14C;
}
.progress.blue .progress-left .progress-bar{
  animation: loading-2 1.5s linear forwards 1.8s;
}
.progress.yellow .progress-bar{
  border-color: #DAA14C;
}
.progress.yellow .progress-value{
  color: #DAA14C;
}
.progress.yellow .progress-left .progress-bar{
  animation: loading-3 1s linear forwards 1.8s;
}
.progress.pink .progress-bar{
  border-color: #DAA14C;
}
.progress.pink .progress-value{
  color: #DAA14C;
}
.progress.pink .progress-left .progress-bar{
  animation: loading-4 0.4s linear forwards 1.8s;
}
.progress.green .progress-bar{
  border-color: #DAA14C;
}
.progress.green .progress-value{
  color: #DAA14C;
}
.progress.green .progress-left .progress-bar{
  animation: loading-5 1.2s linear forwards 1.8s;
}
@keyframes loading-1{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
  }
}
@keyframes loading-2{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(144deg);
      transform: rotate(144deg);
  }
}
@keyframes loading-3{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
  }
}
@keyframes loading-4{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
  }
}
@keyframes loading-5{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(144deg);
      transform: rotate(144deg);
  }
}
@media only screen and (max-width: 990px){
  .progress{ margin-bottom: 20px; }
}

/*work*/
.work{
  width: 100%;
  height: auto;
  background-image: url(images/home/home-interior-design.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 20px;
}
.work-para{
  font-size: 16px;
  color: #DAA14C;
  font-family: 'Plus Jakarta Sans';
  text-transform: uppercase;
}
.work-title{
  color: #ddd;
  margin:60px 0px;
  font-size: 25px;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.work-para1{
  font-size: 16px;
  color: #ddd;
  font-family: 'Plus Jakarta Sans';
}
.work-image{
  background-color: white;
  width: 100%;
  height: 350px;
}
.work-image:hover{
  background: linear-gradient(rgba(0, 0, 0, 0.637),rgba(0,0,0,0.75)),url("../src/images/home/top-rated-interio-design.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.work-inside-title{
  text-align: center;
  color: #DAA14C;
  font-family: 'Plus Jakarta Sans';
  font-size: 18px;
  font-weight: bold;
}
.work-icon{
font-size: 30px;
color: #fdba04;
text-align: center;
padding-top: 120px;
}
.work-inside-para{
  text-align: center;
  color: #DAA14C;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  padding: 10px;
}

/*Variant*/
.variant-para{
  font-size: 16px;
  color: #DAA14C;
  font-family: 'Plus Jakarta Sans';
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 20px;
}
.variant-title{
  font-size: 16px;
}
.variant-para1{
  padding: 20px 20px;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
}
.variant-inside-title{
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #DAA14C;
  text-transform: uppercase;
  font-family: 'Plus Jakarta Sans';
}
.variant-inside-para{
  text-align: center;
  font-size: 18px;
  color: #ddd;
  text-transform: uppercase;
  font-family: 'Plus Jakarta Sans';
}


/*State*/
.state{
  width: 100%;
  height: auto;
  background-image: url(images/home/best-design.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 20px;
}
.state-no{
  font-size: 38px;
  font-weight: bold;
  color: #DAA14C;
  font-family: 'Plus Jakarta Sans';
}
.state-para{
  font-size: 14px;
  color: white;
  font-family: 'Plus Jakarta Sans';
}
.state-inside{
  border-right: dashed;
  color: white;
}

/*Testimonial*/
.testimonial-img{
  width: 100%;
  height: auto;
}

/*Footer*/
.footer{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)),url(images/home/space-living-cookscape.jpg);
  background-position: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px;
}
.footer-title{
  color: #ffffff;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-weight: 600;
}
.footer-para{
  color: #ddd;
  font-size: 12px;
  padding-top: 4px;
  font-family: 'Plus Jakarta Sans';
}
a{
  color: none !important;
  text-decoration: none;
}
.footer-link{
  color: white;
  list-style-type: none;
  padding: 10px 0px;
  font-weight: bold;
}
.footer-link a{
  text-decoration: none;
  color: #252525;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
}
.footer-link a:hover{
  
  color: #fdba04;
}

.social{
  text-align: left;
}
.copyright-para{
  color: rgb(191, 191, 191);
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  padding-top: 40px;
}
.qoute-background{
  background-color: #000000;
  padding: 20px;
}
.qoute-para{
  font-size: 16px;
  color: #DAA14C;
  font-family: 'Plus Jakarta Sans';
  text-transform: uppercase;
}
.qoute-title{
  font-size: 26px;
  font-family: 'Plus Jakarta Sans';
  color: #ddd;
  font-weight: bold;
}
.qoute-button{
  border: none;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  background-color: #DAA14C;
  padding: 10px 25px;
  /*border-radius: 5px;*/
  cursor: pointer;
  font-family: 'Plus Jakarta Sans';
}
.qoute-button:hover{
  color: #DAA14C;
  background-color: #000000;
}
.phone-icon{
  color: #ddd;
  font-size: 25px;
  font-weight: 600;
}

.social-icons1 li{
  display: inline-block;
  color: #ddd;
}

/*Services Breadcrumb*/
.services-breadcrumb{
  width: 100%;
  height: 250px;
  background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)),url(images/home/servicebackground\ .webp);
  background-position: scroll;
  background-size: cover;
  background-repeat: no-repeat;
}

/*Wardrobe, Modularkitchen, Tv unit, Kids furniture, Pooja units, Customer speaks*/
.interior-title{
  color: #DAA14C;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  font-family: 'Plus Jakarta Sans';
}
.interior-para{
  font-size: 14px;
  text-align: center;
  color: rgb(40, 40, 40);
  font-family: 'Plus Jakarta Sans';
}
.interior-col{
  padding: 3px;
}
.interior-col:hover{
  border: 1px solid;
  border: none;
 box-shadow: 5px 10px 15px #888888;
 border-radius: 25px;
}

/*Renovation*/
.renovation-title{
  color: #DAA14C;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}
.renovation-main-title{
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}
.renovation-para{
  color: #252525;
  font-size: 14px;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}

.renovation-icon{
font-size: 30px;
color: #DAA14C;
text-align: left;
}
.renovation-service-title{
font-size: 16px;
font-weight: bold;
text-align: center;
font-family: 'Plus Jakarta Sans';
}

.renovation-section-title{
  color: #000000;
  font-family: 'Plus Jakarta Sans';
}
.renovation-section-title1{
  color: #DAA14C;
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
}
.renovation-section-para{
  color: #252525;
  text-align: justify;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
}
.renovation-icon{
  font-size: 30px;
  color: #252525;
  text-align: left;
  text-align: center;
}
.renovation-service-title{
  font-size: 16px;
  font-weight: bold;
  color: #DAA14C;
  font-family: 'Plus Jakarta Sans';
}

/*Blogs*/
.blog-img{
  width: 100px;
  height: 100px;
  border: 2px solid #DAA14C;
}
.blog-img-inside{
  width: 550px;
  height: 450px;
}
.blog-title{
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.blog-para{
  color: #252525;
  font-size: 14px;
  text-align: justify;
  font-family: 'Plus Jakarta Sans';
}
.ar-img{
  width: 150px;
  height: 150px;
}
.blog-page-title{
  font-size: 25px;
  color: #000000;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.blog-page-para{
  color: #252525;
  font-size: 14px;
  text-align: justify;
  padding: 10px 0px;
  font-family: 'Plus Jakarta Sans';
}
.blog-page-title-2{
  color: #DAA14C;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.blog-page-sub-title{
  color: #DAA14C;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.blog-page-author-img{
  width: 150px;
  height: 150px;
}
.blog-img-size{
  width: 650px;
  height: 550px;
}
.pagination {
  display: inline-block;
}

.pagination a {
  color: #000000;
  float: left;
  padding: 8px 16px;
  font-size: 12px;
  text-decoration: none;
  border: 1px solid #000000;
}

.pagination a.active {
  background-color: #e00000;
  color: white;
  
}

.pagination a:hover:not(.active) {background-color: #e00000;}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/*Group websites*/
.websites{
  padding: 20px 0px;
}
.website-title{
  color: #DAA14C;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.website-title-main{
  color: #DAA14C;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Plus Jakarta Sans';
}
.website-para{
  font-size: 14px;
  text-align: justify;
  color: #282828;
  font-family: 'Plus Jakarta Sans';
}

.group {
  position: relative;
  width: 100%;
  
}
.group-title{
  color: white;
  font-size: 30px;
  padding-top: 130px;
  font-family: 'Plus Jakarta Sans';
  
}
.overlay5 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(255, 0, 0, 0.527);
}
.group:hover .overlay5 {
  opacity: 1;
}

.fa{
  color: #DAA14C;
  padding: 0px 20px 0px 0px ;
  cursor: pointer;
}
.fa:hover{
  color: rgb(255, 255, 255);
}

/*Page not found*/
.page-not-found{
  color: rgb(0, 0, 0);
  font-size: 45px;
  margin-top: 100px;
  font-family: 'Plus Jakarta Sans';
  }

/*Contact*/
.contact-h4{
  color: #DAA14C;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}
.contact-h3{
  color: #252525;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
}
.contact-para{
  color: #252525;
  text-align: center;
  font-size: 14px;
  font-family: 'Plus Jakarta Sans';
}
.contact-panel-1{
  padding: 20px;
}
.head-office{
  text-align: center;
  color: #fdba04;
  font-size: 30px;
  padding: 30px 0px;
  font-family: 'Plus Jakarta Sans';
}
.contact-panel-2{
  border: 1px solid #fdba04;
  border-radius: 25px;
  padding: 20px;
  
}
.forservice{
  font-size: 16px;
  font-weight: bold;
  color: #f9ad4a;
  font-family: 'Plus Jakarta Sans';
}
.maillink{
  color: #252525;
  text-decoration: none;
  font-size: 16px;
}
.circle{
  background-color: #000000;
  width: 100%;
  height: auto;
}
.contact-form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
}
.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

input, textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

textarea {
  resize: vertical;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  display: none; /* Hidden by default */
  font-family: 'Plus Jakarta Sans';
}

.submit-button {
  background-color: #218838;
  color: white;
  font-family: 'Plus Jakarta Sans';
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}

.submit-button:hover {
  background-color: #000000;
}
.modal-title{
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #313131;
  font-family: 'Plus Jakarta Sans';
}

/*Services*/
.service-page-title{
  color: black;
  font-size: 25px;
  font-weight: bold;
  font-family: 'Plus Jakarta Sans';
}
.service-page-para{
  font-size: 14px;
  color: black;
  font-family: 'Plus Jakarta Sans';
  
}
.round{
  background-color: #DAA14C;
  position: absolute;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  margin-top: 300px;
  margin-left: 300px;
  padding: 20px;
}
.round-title{
  font-size: 35px;
  color: white;
  text-align: center;
  margin-top: 25px;
}
.round-para{
  text-align: center;
}

/*Factory*/
.factory-breadcrumb{
  width: 100%;
  height: 550px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.826),rgba(0, 0, 0, 0.502)),url(images/home/Carpenter.jpeg);
  background-position: scroll;
  background-size: cover;
  background-repeat: no-repeat;
}
.factory-main{
  position: absolute;
  top: 50%;
  left: 20%;
}
.factory-main-title{
  color: white;
  font-size: 26px;
  font-family: 'Plus Jakarta Sans';
  text-align: center;
}
.factory-icon-title{
  color: #252525;
  font-weight: bold;
  font-size: 24px;
  font-family: 'Plus Jakarta Sans';
}
.factory-icon-para{
  color: #252525;
  font-size: 14px;
  font-family: 'Plus Jakarta Sans';
}
.factory-icon{
  font-size: 43px;
  padding-left: 30px;
  padding-top: 10px;
  height: 100px;
  width: 100px;
  color: white;
  background-color: #DAA14C;
  border-radius: 50%;
  display: inline-block;
}
.factory-service{
  background-color: #384d62;
}
.blue{
  background-color: blue;
  width: 90%;
}


.cookscape-services-title{
  font-family: 'Plus Jakarta Sans';
  color:  #f9ad4a;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  padding-top: 140px;
}
.services-page-title{
  color: #fdba04;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
  padding: 10px 0px;
}
/*Map*/
.map{
  width: 100%;
  height: 100%;
}

/*Floating Button*/
.adminActions {
  position: fixed;
  bottom: 35px; right: 35px;
}
  .adminButton {
    height: 60px;
    width: 60px;
    background-color: #e00000;
    border-radius: 50%;
    display: block;
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1;
  }

    .adminButton i {
      font-size: 22px;
      color: white;
      padding-left: 20px;
    }

  .adminButtons {
    position: absolute;
    width: 100%;
    bottom: 120%;
    text-align: center;
  }
    .adminButtons a {
      display: block;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      text-decoration: none;
      margin: 10px auto 0;
      line-height: 1.15;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      position: relative;
      box-shadow: 0 0 5px 1px rgba(51, 51, 51, .3);
    }
      .adminButtons a:hover {
        transform: scale(1.05);
        color: white;
      }

      .adminButtons a:nth-child(1) {background-color: #4CAF50; transition: opacity .2s ease-in-out .3s, transform .15s ease-in-out;}
      .adminButtons a:nth-child(2) {background-color: #03a9f4; transition: opacity .2s ease-in-out .25s, transform .15s ease-in-out;}
      .adminButtons a:nth-child(3) {background-color: #f44336; transition: opacity .2s ease-in-out .2s, transform .15s ease-in-out;}
      .adminActions a i {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
      }
  .adminToggle {
    -webkit-appearance: none;
    position: absolute;
    border-radius: 50%;
    top: 0; left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    z-index: 2;
    transition: box-shadow .2s ease-in-out;
    box-shadow: 0 3px 5px 1px rgba(51, 51, 51, .3);
  }

    .adminToggle:hover {
      box-shadow: 0 3px 6px 2px rgba(51, 51, 51, .3);
    }

    .adminToggle:checked ~ .adminButtons a {
      opacity: 1;
      visibility: visible;
    }
    .round{
      color: white;
      font-size: 14px;
      padding-left: 20px;
    }

    /*Vertical Button*/
    .vertical-text{
      text-orientation: sideways;
      writing-mode: vertical-rl; 
    }
    .vertical-button{
      background-color: #DAA14C;
      color: #000000;
      border: none;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 14px;
    }
    .vertical-button:hover{
      background-color: #000000;
    }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
.main-para{
  font-size: 14px;
}
.main-title{
  font-size: 28px;
}
.banner{
  width: 100%;
  height: 300px !important;
}
.box{
  display: none;
}
.state-no{
  font-size: 20px;
}
.state-inside{
  border: none;
}
.logo1{
height: 25%;
}
.faq-image{
height: 350px;
width: 380px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .main-para{
    font-size: 16px;
  }
  .main-title{
    font-size: 40px;
  }
  .banner{
    width: 100%;
    height: 450px;
  }
  .box{
    display: none;
  }
  .faq-image{
    margin: 0px;
    width: 150px;
    height: 150px;
  }
  .logo1{
    height: 30%;
    }
    .state-inside{
      border: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .services-title-3{
    font-size: 12px;
  }
  .services-para{
    font-size: 12px;
  }
  .work-title{
    font-size: 18px;
  }
  .work-para1{
    font-size: 14px;
  }
  .work-icon{
    font-size: 22px;
    padding-top: 40px;
  }
  .work-image{
    height: 250px;
  }
  .work-inside-title{
    font-size: 14px;
  }
  .work-inside-para{
    font-size: 12px;
  }
  .faq-image{
    margin: 0px;
    width: 200px;
    height: 200px;
  }
  .state-no {
    font-size: 25px;
  }
  .state-para{
    font-size: 12px;
  }
  .logo1{
    height: 10%;
    }
    .phone-icon{
      font-size: 16px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .phone-icon{
    font-size: 16px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .phone-icon{
    font-size: 16px;
  }
}
  
     
      